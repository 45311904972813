#question-container {
  #time-left {
    p {
      text-align: center;
      font-size: 24px;
      margin-top: 35px;

      &.red-text {
        color: red;
        font-weight: bold;
      }


    }
  }

  width: 500px;
  @media (max-width: 500px) {
    width: 100%;
  }

  padding: 15px;
}
