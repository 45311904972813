#multi-step-header {
  width: 100%;
  color: #123A58;
  font-size: 16px;
  font-weight: 700;
  border-bottom: 1px solid #99b2cc;
  text-align: center;
  padding-bottom: 10px;
  margin-bottom: 0;
}
