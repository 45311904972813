.dropdown {
  margin-bottom: 20px;
  width: 100%;

  &-label {
    font-weight: 700;
    margin-bottom: 10px;
    text-align: left;
    color: #123A58;
    font-size: 14px;
  }

  &-wrapper {
    align-items: center;
    border: 1px solid #123A58;
    border-radius: 5px;
    box-sizing: border-box;
    display: flex;
    height: 50px;
    letter-spacing: 0.2px;
    padding: 10px 6px;
    position: relative;
    width: 100%;
  }

  &-placeholder {
    color: #123A58;
    font-size: 16px;
  }

  &-select {
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}
