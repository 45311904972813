.progress-bar-container {
  height: 3px;
  width: 100%;
  background-color: #e0e0de;
  border-radius: 1.5px;

  .progress-bar-filler {
    height: 100%;
    background-color: #00cc00;
    border-radius: inherit;
    text-align: right;
    transition: width 0.5s ease-in-out;

    .progress-bar-label {
      padding: 5px;
      color: white;
      font-weight: 500;
    }
  }
}

