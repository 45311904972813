#question-text-container {
  display: flex;
  align-items: flex-end;
  height: 100px;
  padding: 15px 0 40px 0;

  #question-text {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    text-align: center;
  }
}

#answer-slider-container {
  position: relative;

  #slider {
    -webkit-appearance: none;
    width: 100%;
    height: 12px;
    border-radius: 8px;
    background: rgb(224, 224, 222);
    outline: none;
    cursor: pointer;
  }

  #slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #275efe;
    border: 2px solid #ffffff;
    cursor: pointer;
  }

  #slider::-moz-range-thumb {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: #275efe;
    cursor: pointer;
  }

  #slider-filler {
    height: 12px;
    background: #275efe;
    border-radius: 8px 0 0 8px;
    position: absolute;
    top: 5px;
    left: 4px;
    pointer-events: none;
  }

  #slider-handle-value {
    color: #ffffff;
    position: absolute;
    top: 0;
    pointer-events: none;
  }
}

#slider-label-container {
  height: 100px;
  display: flex;
  justify-content: space-between;
  padding-top: 15px;

  .slider-label {
    font-size: 12px;
  }

  .label-left {
    text-align: left;
  }

  .label-right {
    text-align: right;
  }
}

#next-btn-container {
  width: 100%;
  height: 50px;

  #next-btn {
    width: 100%;
    height: 100%;
    font-weight: bold;
    font-size: 16px;
    color: white;
    background: #275efe;
    border: none;
    outline: none;
    border-radius: 70px;
    cursor: pointer;

    &:disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}
