.App {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;

  #questionnaire_completed {
    width: 100%;
    margin-top: 10px;
    text-align: center;

    #upscore-logo {
      max-width: 200px;
      margin-bottom: 40px;
    }

    #questionnaire_completed-wrapper {
      justify-content: center;
      display: flex;
    }

    #questionnaire_completed-container {
      align-items: center;
      display: flex;
      flex-direction: column;
      text-align: center;
      width: 80%;

      p {
        font-size: 24px;
        font-weight: bold;
        margin: 10px;
      }
    }
  }
}

.text-centered {
  text-align: center;
}
