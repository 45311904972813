#questionnaire-completed-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  #upscore-logo {
    max-width: 200px;
    margin-bottom: 40px;
  }

  p {
    font-size: 24px;
    font-weight: bold;
    margin: 10px;
  }
}
