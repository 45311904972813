#question-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100px;
  padding: 15px 0 40px 0;

  #question-text {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    text-align: center;
  }

  #question-text2 {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    text-align: center;
  }
}

#question-video-wrapper {
  width: 100%;
  margin-top: 48px
}

#boolean_answer-button-container {
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-content: flex-start;

  .answer-button {
    width: 100%;
    height: 50px;
    font-weight: bold;
    font-size: 16px;
    color: white;
    background: #275efe;
    border: none;
    outline: none;
    border-radius: 70px;
    cursor: pointer;

    &:disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}
