#intro-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  #upscore-logo {
    max-width: 200px;
    margin-bottom: 40px;
  }

  #intro-header {
    margin: 10px 0;
    font-size: 24px;
    font-weight: bold;
  }

  .intro-text {
    margin: 10px 0;
  }

  #intro-start-button {
    margin: 20px 0;
    width: 100%;
    height: 50px;
    font-weight: bold;
    font-size: 16px;
    color: white;
    background: #275efe;
    border: none;
    outline: none;
    border-radius: 70px;
    cursor: pointer;

    &:disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}
