#extra_info {
  width: 100%;

  #extra_info-wrapper {
    justify-content: center;
    display: flex;
  }

  #extra_info-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    max-width: 270px;
    text-align: center;
    width: 80%;

    button {
      background: #123A58;
      border: none;
      border-radius: 70px;
      box-shadow: 2px 2px 6px -2px #000000;
      color: white;
      cursor: pointer;
      height: 40px;
      font-weight: bold;
      font-size: 16px;
      margin: 10px 0;
      outline: none;
      padding: 11px 54px;

      &:disabled {
        opacity: 0.5;
      }
    }
  }
}
