#questionnaire-expired-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  #upscore-logo {
    max-width: 200px;
    margin-bottom: 40px;
  }

  p {
    font-size: 24px;
    font-weight: bold;
    margin: 10px;
  }

  .video-review-site-button {
    width: 100%;
    height: 50px;
    font-weight: bold;
    font-size: 16px;
    color: white;
    background: #275efe;
    border: none;
    outline: none;
    border-radius: 70px;
    cursor: pointer;
    opacity: 0;

    &.visible {
      opacity: 1;
    }
  }
}
